export function ReviewsSlider(rootEl) {
  const data = JSON.parse(document.getElementById("reviewsData").textContent)

  let currentReviewIdx = 0

  const customerNameEl = rootEl.querySelector("#customer-name")
  const reviewTextEl = rootEl.querySelector("#review-text")
  const projectNameEl = rootEl.querySelector("#project-name")
  const customerImgEl = rootEl.querySelector("#customer-image")
  const reviewContainerEl = rootEl.querySelector("#review-container")

  function pushContent(){
    const currentReview = data[currentReviewIdx]
    customerNameEl.innerHTML = currentReview.customerName
    reviewTextEl.innerHTML = currentReview.customerReviewBlurb
    projectNameEl.innerHTML = `${currentReview.wearerName}'s Engagement Ring`
    customerImgEl.setAttribute('src', currentReview.customerAvatarUrl)
    reviewContainerEl.classList.remove('changing')
  }

  function changeReview(delta){
    reviewContainerEl.classList.add('changing')
    currentReviewIdx += delta
    if (currentReviewIdx > data.length - 1) {
      currentReviewIdx = 0
    } else if (currentReviewIdx < 0) {
      currentReviewIdx = data.length - 1;
    }
    setTimeout(pushContent, 1000);
  }

  const rotateInterval = setInterval(() => changeReview(1), 8500);

  rootEl.querySelector('.review-advance.forwards').addEventListener('click', function(ev) {
    ev.preventDefault()
    clearInterval(rotateInterval)
    changeReview(1)
  })

  rootEl.querySelector('.review-advance.backwards').addEventListener('click', function(ev) {
    ev.preventDefault()
    clearInterval(rotateInterval)
    changeReview(-1)
  })
}
