export function BudgetExplorer(rootEl) {
  const data = JSON.parse(document.getElementById("budgetExplorerData").textContent)
  let currentRangeIdx = Math.round((data.length - 1) / 2)

  const projectImagesWrapperEl = rootEl.querySelector(".explorer-images-wrapper")
  const sliderInputEl = rootEl.querySelector("input#budget-input-slider")
  const sliderOutputEl = rootEl.querySelector("output.budget-slider-output")

  sliderInputEl.setAttribute('max', data.length - 1)
  sliderInputEl.value = currentRangeIdx
  sliderOutputEl.innerHTML = data[currentRangeIdx]?.priceRange

  function renderProjectImages(initialRender) {
    const projDivs = data[currentRangeIdx]?.projects?.map(function(project, projIdx) {
      const additionalImgs = project.additionalImages.slice(1).map(function(image) {
        const a = document.createElement("a")
        a.setAttribute("href", image)
        a.setAttribute("rel", `budget-${projIdx}`)
        a.setAttribute("data-fancybox", "")
        a.appendChild(new Text("Additional ring images"))
        return a
      })

      const mainImgA = document.createElement("a")
      mainImgA.setAttribute("href", project.mainImage.full)
      mainImgA.setAttribute("rel", `budget-${projIdx}`)
      mainImgA.setAttribute("data-fancybox", "")

      const mainPic = document.createElement("picture")
      const mainPicSource = document.createElement("source")
      mainPicSource.srcset = `${project.mainImage.thumb1x}, ${project.mainImage.thumb2x} 2x`
      mainPic.appendChild(mainPicSource)
      const mainImg = document.createElement("img")
      mainImg.setAttribute("alt", project.description)
      if (initialRender) { mainImg.setAttribute("loading", "lazy") }
      mainImg.setAttribute("src", project.mainImage.thumb1x)
      mainPic.appendChild(mainImg)
      mainImgA.appendChild(mainPic)

      const additionalImgsWrapper = document.createElement("div")
      additionalImgsWrapper.className = "budget-hidden-image-container"
      additionalImgsWrapper.innerHTML = ""
      for (const img of additionalImgs) { additionalImgsWrapper.appendChild(img) }

      const projDiv = document.createElement("div")
      projDiv.className = `image-container image-container-${projIdx}`
      projDiv.innerHTML = ""
      projDiv.appendChild(mainImgA)
      projDiv.appendChild(additionalImgsWrapper)

      return projDiv
    })

    projectImagesWrapperEl.innerHTML = ""
    for (const projDiv of projDivs) { projectImagesWrapperEl.appendChild(projDiv) }
  }

  sliderInputEl.addEventListener('input', function(ev) {
    currentRangeIdx = ev.target.valueAsNumber
    sliderOutputEl.innerHTML = data[currentRangeIdx]?.priceRange
    if (document.startViewTransition) {
      document.startViewTransition(() => renderProjectImages(false))
    } else {
      renderProjectImages(false)
    }
  })

  renderProjectImages(true)
}
