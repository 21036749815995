import {trackEventSP} from 'js/studios/shared/tracking'

export function setupInstagram() {
  const instagramModal = document.querySelector('#instagram-contents-modal')
  const instagramContents = instagramModal.querySelector("#instagram-inner-contents")

  function closeModal(ev) {
    ev?.preventDefault()
    instagramModal.classList.remove("visible")
    document.body.style.overflow = 'auto'
    instagramContents.innerHTML = ""
  }

  instagramModal.addEventListener("click", closeModal)
  instagramModal.querySelector("#instagram-modal-close").addEventListener('click', closeModal)

  Array.from(document.querySelectorAll('[data-ig-content]')).forEach(link => {
    link.addEventListener('click', function(ev) {
      ev.preventDefault()

      const dataSource = ev.currentTarget.dataset.source

      fetch(`https://graph.facebook.com/v17.0/instagram_oembed/?url=${dataSource}&access_token=149145561806492|d34db68e8dc0504f868465844f28778e`)
        .then(resp => resp.json())
        .then(json => {
          document.body.style.overflow = "hidden"
          instagramModal.classList.add("visible")
          instagramContents.innerHTML = json.html
          window.instgrm.Embeds.process()
          trackEventSP('click_instagram_item', 'browse', { label: json.media_id })
        }).catch(_err => {
          closeModal()
          window.open(dataSource)
        })
    })
  })
}
