import {trackEventSP} from 'js/studios/shared/tracking'

export function AltCategoryCta() {
  const URL_MAPPINGS = {
    wedding_band_set: "/custom-wedding-rings/",
    necklace: "/custom-necklaces/",
    signet_ring: "/custom-signet-rings/",
    class_ring: "/custom-class-rings/",
    earrings: "/custom-earrings/",
    cufflinks: "/custom-cufflinks/",
    other_ring: "/custom-rings/"
  }

  const IMAGE_MAPPINGS = {
    wedding_band_set: "https://images.custommade.com/U7f9CrnoQD4zTpGOkTtPdvLSIR8=/custommade-attachments/525ce018b56c182_alt_category_wedding_band.jpg",
    necklace: "https://images.custommade.com/iXorR5AZUXLedh21SEi4tW3D448=/custommade-attachments/61a8fb0a2c49f4e_alt_category_pendants.jpg",
    signet_ring: "https://images.custommade.com/C2NyO5fewZApE53--IT-n1Bu2tQ=/custommade-attachments/35a04943850e978_alt_category_signet.jpg",
    class_ring: "https://images.custommade.com/uY7szoA-ce6wmE23iu1oRwsGdV4=/custommade-attachments/e179ecf9c5d2f6f_alt_category_class.jpg",
    earrings: "https://images.custommade.com/tNGrHzKDGvPoHVP0QO_9_Ndp28Y=/custommade-attachments/0479c9ffdc271bc_alt_category_earring.jpg",
    cufflinks: "https://images.custommade.com/qaqchgN9MD5o3k-vI7TkveA_Mjs=/custommade-attachments/8c25a929efbdca5_alt_category_cufflinks.jpg",
    other_ring: "https://images.custommade.com/4XEsVoAxpJwUw45T65pP5HFEG-Q=/custommade-attachments/24a8f364449e5b5_alt_category_statement.jpg"
  }

  const ALTTEXT_MAPPINGS = {
    wedding_band_set: "Rose gold wedding band",
    necklace: "Moonstone necklace",
    signet_ring: "Gold family crest signet ring",
    class_ring: "Gold class ring",
    earrings: "Gold clover and heart earrings",
    cufflinks: "Coat of arms cufflinks",
    other_ring: "Filigree statement ring"
  }

  const selectNode = document.getElementById('alternate-category-select-field')
  const learnMoreBtn = document.getElementById('alternate-category-select-submit')
  const startOtherLink = document.getElementById('start-other-jewelry-project')
  const altCatImg = document.getElementById('alt-category-image')

  selectNode.addEventListener('change', function(ev) {
    if (ev.target.value) {
      Array.from(document.querySelectorAll('.alternate-categories-form .btn')).forEach(n => {
        n.classList.add('active')
      })
      startOtherLink.setAttribute('href', `/design-with-us/?category=${ev.target.value}`)
      altCatImg.setAttribute("src", IMAGE_MAPPINGS[ev.target.value])
      altCatImg.setAttribute("alt", ALTTEXT_MAPPINGS[ev.target.value])
    } else {
      Array.from(document.querySelectorAll('.alternate-categories-form .btn')).forEach(n => {
        n.classList.remove('active')
      })
    }
  })

  learnMoreBtn.addEventListener('click', function(ev) {
    ev.preventDefault()
    if (selectNode.value?.length > 1) {
      trackEventSP('click_body_link', 'browse', { label: `Learn More: ${URL_MAPPINGS[selectNode.value]}` })
      window.location.href = URL_MAPPINGS[selectNode.value]
    }
  })
}
