import {trackEventSP} from 'js/studios/shared/tracking'

export function StyleExplorer(rootEl) {
  const data = JSON.parse(document.getElementById("styleExplorerData").textContent)

  const visibleProjDiv = rootEl.querySelector("div.style-type-visible-project")

  function renderNav() {
    const wrapper = rootEl.querySelector("div.style-type-slides")
    const navDivs = data.map(function(proj, projIdx) {
      const div = document.createElement("div")
      div.className = "style-type-slide-item"
      div.addEventListener("click", function(ev) {
        ev.preventDefault()
        if (document.startViewTransition) {
          document.startViewTransition(() => changeVisibleProject(projIdx))
        } else {
          changeVisibleProject(projIdx)
        }
        trackEventSP('click_style_explorer_advance', 'browse')
      })
      const img = document.createElement("img")
      img.setAttribute("alt", proj.projectDetails)
      img.setAttribute("loading", "lazy")
      img.setAttribute("src", proj.projectThumbUrl)
      div.appendChild(img)
      return div
    })
    wrapper.innerHTML = ""
    for (const navDiv of navDivs) { wrapper.appendChild(navDiv) }
  }
  renderNav()

  function projectAdditionalImageAnchors(proj) {
    return proj.projectImages.slice(1).map(function(img) {
      const a = document.createElement("a")
      a.setAttribute("rel", "style-images")
      a.setAttribute("href", img)
      a.setAttribute("data-fancybox", "")
      a.appendChild(new Text("Additional ring image."))
      return a
    })
  }

  function renderVisibleProject() {
    const proj = data[0]

    const mainA = document.createElement("a")
    mainA.setAttribute("rel", "style-images")
    mainA.setAttribute("data-fancybox", "")
    mainA.setAttribute("href", proj.projectImages[0])
    const mainAPic = document.createElement("picture")
    mainAPic.className = "main-project-image"
    const mainAPicSrc = document.createElement("source")
    mainAPicSrc.srcset = `${proj.mainImageUrl}, ${proj.mainImageUrl2x} 2x`
    mainAPic.appendChild(mainAPicSrc)
    const mainAPicImg = document.createElement("img")
    mainAPicImg.setAttribute("alt", proj.projectDetails)
    mainAPicImg.setAttribute("loading", "lazy")
    mainAPicImg.setAttribute("src", proj.mainImageUrl)
    mainAPic.appendChild(mainAPicImg)
    mainA.appendChild(mainAPic)

    let madeForImg = null
    if (proj.labelUrl) {
      madeForImg = document.createElement("img")
      madeForImg.className = "made-for-label"
      madeForImg.setAttribute("alt", "Stylized 'Made for' label")
      madeForImg.setAttribute("src", proj.labelUrl)
    }

    let sketchImg = null
    if (proj.sketchThumbUrl) {
      sketchImg = document.createElement("img")
      sketchImg.className = "project-sketch-thumb"
      sketchImg.setAttribute("alt", "Sketch of customer's ring design")
      sketchImg.setAttribute("src", proj.sketchThumbUrl)
    }

    const additionalImgsDiv = document.createElement("div")
    additionalImgsDiv.className = "style-additional-images-container"
    additionalImgsDiv.innerHTML = ""
    for (const addtlImgA of projectAdditionalImageAnchors(proj)) { additionalImgsDiv.appendChild(addtlImgA) }

    visibleProjDiv.innerHTML = ""
    visibleProjDiv.appendChild(mainA)
    if(madeForImg) { visibleProjDiv.appendChild(madeForImg) }
    if(sketchImg) { visibleProjDiv.appendChild(sketchImg) }
    visibleProjDiv.appendChild(additionalImgsDiv)
  }
  renderVisibleProject()

  function changeVisibleProject(projIdx) {
    const proj = data[projIdx]

    const mainA = visibleProjDiv.querySelector("a[rel=style-images]")
    mainA.setAttribute("href", proj.projectImages[0])
    const mainAPicSrc = mainA.querySelector("picture source")
    mainAPicSrc.srcset = `${proj.mainImageUrl}, ${proj.mainImageUrl2x} 2x`
    const mainAPicImg = mainA.querySelector("picture img")
    mainAPicImg.setAttribute("alt", proj.projectDetails)
    mainAPicImg.setAttribute("src", proj.mainImageUrl)

    const madeForImg = visibleProjDiv.querySelector("img.made-for-label")
    if (madeForImg) {
      madeForImg.setAttribute("src", proj.labelUrl)
    }

    const sketchImg = visibleProjDiv.querySelector("img.project-sketch-thumb")
    if (sketchImg) {
      sketchImg.setAttribute("src", proj.sketchThumbUrl)
    }

    const additionalImgs = visibleProjDiv.querySelector(".style-additional-images-container")
    additionalImgs.innerHTML = ""
    for (const addtlImgA of projectAdditionalImageAnchors(proj)) { additionalImgs.appendChild(addtlImgA) }
  }
}
