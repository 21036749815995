const _ = window._,
  $ = window.$;

/**
 * Takes in the event object and a data attribute which to listen to.
 *
 * @param {object} e the event object
 * @param {string} attribute the data-* attribute to listen to.
 */
function eventDelegator(e, func, attribute) {
  var i, attrValue,
      target = e.target;

  for (i = 0; i < 3; i++) {
    if (target && target.getAttribute && (attrValue = target.getAttribute('data-' + attribute))) {
      func(attrValue);
      break;
    } else {
      if (target.parentNode) {
        target = target.parentNode;
      } else {
        break;
      }
    }
  }
}


/**
 * Sends a trackPageView to Snowplow.
 *
 * @param {string} path The path reported on.
 */
export function sendPageView() {
  window.dataLayer.push({'event': 'virtualPageView'});
}

/**
 * Fires a tracking event for Snowplow.
 * When in debugging mode (DEBUG == true), prints out the tracking event.
 * Otherwise, pushes the event to the Google Tag Manager (GTM) dataLayer.
 * Once in there, GTM is configured to send the event to Snowplow.
 *
 * @param  {string} event          Name of the Snowplow event
 * @param  {string} event_category Category of the event
 * @param  {object} fields         Any number of other fiends {'field_name': 'value', ...}
 */
export function trackEventSP(event, event_category, fields) {
  var eventData = _.extend({
    'event': event,
    'event_category': event_category
  }, fields);

  if (window.DEBUG) {
    console.log('trackEventSP', eventData);
  }
  else {
    window.dataLayer.push(eventData);
  }
}

export function trackEventSPHelper(attrValue){
    var split = attrValue.split('*');
    var i, len, attr, args = [], fields = {};

    if (split.length > 1) {

      for (i = 2, len = split.length; i < len; i++) {
        attr = split[i].split('=');
        if (attr.length === 2) {
          fields[attr[0]] = attr[1];
        }
      }
      args.push(split[0], split[1], fields);

      trackEventSP.apply(window, args);
    }
}

/**
 * For Snowplow Tracking
 * Checks to see if any element which has been clicked on contains an
 * attribute of:
 *
 * data-track-event-sp
 *
 * If so, it will look for the value in the following format:
 *
 * event*event_category*field_name=value*field_name_2=value_2
 *
 * @example
 * <a href="/foo" data-track-event-sp="event*event_category*field_name=value*field_name_2=value_2">foo</a>
 */

export function listenForTrackEvents() {
  $(document.body).bind('click', function(e) {
    eventDelegator(e, trackEventSPHelper, 'track-event-sp');
  });
}

/**
 * Tracks when an element is loaded (generally used in CMS slots)
 * Currently does not work for elements that are loaded by javascript (like html in underscore templates)
 *
 * To use:
 * 1. Add the class "track_view" to the element
 * 2. Add data-track-view="event*event_category*field_name=value*field_name_2=value_2"
 *
 * Example:
 * <div class="track_view" data-track-view="View house ad*House ads*ad_text=Start a project*location=homepage*position=Homepage hero"></div>
 */
export function trackViewEvents() {
  _.defer(function(){
      $('.track_view').each(function() {
      var attrValue = this.getAttribute('data-track-view');
      if (attrValue) {
        trackEventSPHelper(attrValue);
      }
    });
  });
}
