const $ = window.$

import {BudgetExplorer} from "./budget_explorer"
import {StyleExplorer} from "./style_explorer"
import {AltCategoryCta} from "./alt_category_cta"
import {ReviewsSlider} from "./reviews_slider"
import {setupInstagram} from "./instagram"
import {OnVisibleObserver} from "js/studios/shared/visibility_observer"
import {trackEventSP} from 'js/studios/shared/tracking'

//--------------------------------
//Sets FancyBox Params
//--------------------------------

window.cm.fancyboxParams = {
  transitionIn: 'elastic',
  transitionOut: 'elastic',
  speedIn:  200,
  speedOut:  200,
  overlayColor: '#fff',
  overlayOpacity: '0.9',
  padding: 0,
  helpers:  {
    title: { type: 'over', position: 'bottom' },
    thumbs: { width: 100, height: 100 }
  },
  beforeShow: function(){
    trackEventSP('click_expand_image', 'browse');
    this.title = $(this.element).find('img').attr('alt');
  }
}


document.addEventListener("DOMContentLoaded", function() {
  //Binds Fancybox
  $('[data-fancybox]').fancybox(window.cm.fancyboxParams)

  BudgetExplorer(document.getElementById('budget-explorer-app'))
  StyleExplorer(document.getElementById('style-app-container'))
  AltCategoryCta()
  ReviewsSlider(document.querySelector("section#customer-reviews"))
  setupInstagram()

  const visibleHandler = new OnVisibleObserver({rootMargin: '-150px'})
  Array.from(document.querySelectorAll(".trigged-on-scroll")).forEach(n => {
    visibleHandler.handle(n, n => {
      n.classList.add("in-view")

      Array.from(n.querySelectorAll("video[data-lazy]")).forEach(video => {
        Array.from(video.querySelectorAll("source")).forEach(sourceEl => sourceEl.src = sourceEl.dataset.src)
        video.load()
        video.play().catch(() => {/*nop*/})
      })
    })
  })
})
