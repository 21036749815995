const _ = window._

// shims enough of IntersectionObserver for our needs for older browsers
// https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver
export class IntersectionObserverShim {
  constructor(callback, opts) {
    this.callback = callback
    this.observingNodes = []
    this.rootMargin = parseInt(opts?.rootMargin || '0px', 10)
    this.debouncedOnScroll = _.debounce(this.onScroll, 100)
    document.addEventListener("scroll", this.debouncedOnScroll)
  }

  buildNodeEntry = (el) => {
    const rect = el.getBoundingClientRect()
    const isIntersecting = (rect.top - this.rootMargin >= 0 && rect.top - this.rootMargin <= window.screen.height) ||
      (rect.bottom + this.rootMargin > 0 && rect.bottom + this.rootMargin <= window.screen.height)
    return { isIntersecting: isIntersecting, target: el }
  }

  onScroll = (_ev) => {
    const entries = this.observingNodes.map(this.buildNodeEntry)
    this.callback(entries, this)
  }

  observe = (node) => {
    this.observingNodes.push(node)
    this.callback([this.buildNodeEntry(node)], this)
  }
  unobserve = (node) => this.observingNodes = this.observingNodes.filter(n => n !== node)
  disconnect = () => document.removeEventListener("scroll", this.debouncedOnScroll)
}

// Wrap an intersection observer to trigger a callback the first time an element becomes visible.
export class OnVisibleObserver {
  constructor(opts) {
    this.callbacks = [] // stored as pairs of [element, callback]
    this.opts = opts
  }

  constructObserver = () => {
    const ObserverClass = "IntersectionObserver" in window ? IntersectionObserver : IntersectionObserverShim
    this.observer = new ObserverClass(this.onObserve, this.opts)
  }

  handle = (el, fn) => {
    this.callbacks.push([el, fn])
    this.observer || this.constructObserver()
    this.observer.observe(el)
  }

  onObserve = (entries, observer) => {
    entries.forEach(e => {
      if (e.isIntersecting) {
        const pair = this.callbacks.find(p => p[0] === e.target)
        if (!pair) {
          throw new Error(`OnVisibleObserver couldn't find registered callback for element ${e.target}`)
        }
        pair[1](e.target)
        observer.unobserve(e.target)
        this.callbacks = this.callbacks.filter(p => p[0] !== e.target)
      }
    })

    if (Object.keys(this.callbacks).length === 0) {
      this.observer.disconnect()
      this.observer = null
    }
  }
}

